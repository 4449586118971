import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { testimonialVar, testimonialCredVar, testimonialChildrenVar } from '../../utils/framerVariants';

import InView from '../InView';

const Testimonial = ( { author, organization, quote } ) => {
	return (
		<InView scale={0.995} className="testimonial">
			<span className="quotes">“</span>
			<motion.blockquote variants={ testimonialVar } initial="hidden" animate="visible" className="quote">{ quote }</motion.blockquote>
			<motion.span className="testimonialCredVar overline" variants={ testimonialCredVar } initial="hidden" animate="visible">
				<motion.span variants={ testimonialChildrenVar } className="author">{ author }{' - '}</motion.span>
				<motion.span variants={ testimonialChildrenVar } className="organization">{ organization }</motion.span>
			</motion.span>
		</InView>
	);
};

Testimonial.propTypes = {
	author: PropTypes.string.isRequired,
	logo: PropTypes.object,
	organization: PropTypes.string.isRequired,
	quote: PropTypes.string.isRequired
};

export default Testimonial;
