import React from 'react';
import PropTypes from 'prop-types';

import LandingHero from '../../../components/Hero/Landing';
import usePageBody from '../../../hooks/usePageBody';

const HomePageTemplate = ( { headings, pageBody, preview, getAsset } ) => {
	const { renderPageBody, renderPageBodyPreview } = usePageBody();

	return (
		<>
			<div id="loading" className="loading--theme">
				<div id="loading-center">
					<span className="loader">
					{/*
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.42 79.99"><g><polygon className="cls-2" points="19.55 9.21 19.55 16.79 7.38 24.17 7.38 55.1 34.68 71.26 38.51 69.26 38.51 18.19 56.46 28.36 56.46 50.91 44.49 57.89 44.29 73.65 34.68 78.84 1 58.89 1 20.18 19.55 9.21"/><polygon className="cls-3" points="44.69 29.36 50.72 33.15 50.72 45.92 44.69 49.31 44.69 29.36"/><polygon className="cls-1" points="49.87 70.78 49.87 63.19 62.04 55.81 62.04 24.89 34.75 8.73 30.92 10.72 30.92 61.8 12.96 51.62 12.96 29.08 24.93 22.1 25.13 6.34 34.75 1.15 68.42 21.1 68.42 59.8 49.87 70.78"/><polygon className="cls-3" points="24.73 51.63 18.7 47.84 18.7 35.07 24.73 31.68 24.73 51.63"/></g></svg>	
					*/}
					
					</span>
				</div>
			</div>
			<div id="page" className="page font--jakarta">
				<header>
					<LandingHero headings={ headings }/>
				</header>
				<main id="landing-content">
					{ pageBody
					&& pageBody.map( ( component, i ) => !preview
						? renderPageBody( { ...component, i } )
						: renderPageBodyPreview( { ...component, i }, getAsset ) ) }
				</main>
			</div>
		</>
	);
};

HomePageTemplate.propTypes = {
	headings: PropTypes.array,
	pageBody: PropTypes.array,
	preview: PropTypes.bool,
	getAsset: PropTypes.func
};

export default HomePageTemplate;
