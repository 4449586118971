import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import HomePageTemplate from '../templates/pages/home';
import Testimonials from '../components/Testimonials';

const IndexPage = ( { data } ) => {
	const {
		file: {
			childMarkdownRemark: {
				frontmatter: {
					landingBanner: { headings },
					pageBody
				}
			}
		}
	} = data;

	return (
		<Layout>
			<HomePageTemplate
				headings={ headings }
				pageBody={ pageBody }
			/>
			<Testimonials />
		</Layout>
	);
};

IndexPage.propTypes = { data: PropTypes.object };

export default IndexPage;

export const query = graphql`
	query HomePage {
		file(relativePath: {eq: "pages/home.md"}) {
			childMarkdownRemark {
				frontmatter {
					landingBanner {
						headings {
							heading
							subHeading
						}
					}
					pageBody {
						type
						htmlInput
						features {
							moduleId
							heading
							variant
							img {
								childImageSharp {
									fluid(maxWidth: 1400, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
								extension
								publicURL
							}
							croppedImg
							imgBorder
							backgroundColor
							backgroundImg
							textColor
							headerColor
							border
							copy {
								type
								text
								dropDownItem {
									title
									heading
									copy
								}
								button {
									type
									link
									buttonText
									variant
								}
							}
						}
						cardGroup {
							moduleId
							heading
							headerColor
							cardsLayout
							card {
								type
								moduleId
								nav {
									dynamicLink
									staticLink
								}
								heading
								subHeading
								icon {
									childImageSharp {
										fluid(maxWidth: 1400, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
									extension
									publicURL
								}
								img {
									childImageSharp {
										fluid(maxWidth: 1400, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
									extension
									publicURL
								}
								navCopy
								featureCopy {
									type
									text
									buttons {
										type
										link
										buttonText
										variant
									}
								}
							}
						}
						featureCarousels {
							moduleId
							heading
							variant
							img {
								childImageSharp {
									fluid(maxWidth: 1400, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
							croppedImg
							imgBorder
							backgroundColor
							backgroundImg
							textColor
							headerColor
							border
							copy {
								type
								text
								dropDownItem {
									title
									heading
									copy
								}
								button {
									type
									link
									buttonText
									variant
								}
							}
						}
						banner {
							moduleId
							heading
							copy
							button {
								type
								link
								buttonText
								variant
							}
							backgroundColor
							backgroundImg {
								childImageSharp {
									fluid(maxWidth: 1400, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
								extension
								publicURL
							}
							headerColor
							textColor
							border
						}
					}
				}
			}
		}
	}
	`;

/* need to account for this not existing
	   backgroundImage {
	   	childImageSharp {
	   		fluid(maxWidth: 1600, quality: 100) {
	   			...GatsbyImageSharpFluid
	   		}
	   	}
	   } */
