import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Testimonial from './Testimonial';

import Section from '../Section';

const Testimonials = () => {
	const { allFile: { nodes: testimonials } } = useStaticQuery( graphql`
		query Testimonials {
			allFile(filter: {relativeDirectory: {eq: "testimonials"}}) {
				nodes {
					childMarkdownRemark {
						frontmatter {
							author
							organization
							quote
							logo {
								childImageSharp {
									fluid(maxWidth: 1400, quality: 100) {
										...GatsbyImageSharpFluid
                  }
                }
              }
						}
					}
					name
				}
			}
		}
	` );

	const interval = useRef();

	const [ activeIndex, setActiveIndex ] = useState( 0 );
	const [ frequency, setFrequency ] = useState( 5000 );

	const mappedTestimonials = useMemo(
		() => testimonials.map( ( { name: key, childMarkdownRemark: { frontmatter: { author, organization, quote, logo } } } ) => (
			<Testimonial
				key={key}
				logo={logo}
				author={author}
				organization={organization}
				quote={quote} />
		) )
		, [ testimonials ]
	);

	const mappedLogos = useCallback( () => testimonials.map( ( { name: key, childMarkdownRemark: { frontmatter: { logo } } } ) => (
		<div key={key} className="logo">
			<img src={logo.childImageSharp.fluid.src} alt="logo" />
		</div>
	) ), [ testimonials ] );

	useEffect( () => {
		interval.current = setInterval( () => {
			setActiveIndex( prev => prev >= mappedTestimonials.length - 1 ? 0 : prev + 1 );

			if ( frequency !== 20000 ) {
				setFrequency( 20000 );
			}
		}, frequency );

		return () => {
			clearInterval( interval.current );
			interval.current = null;
		};
	}, [ frequency, interval, mappedTestimonials.length ] );

	const mapSelectors = useCallback(
		() => testimonials.map( ( { name: key }, index ) => {
			const handleClick = () => {
				if ( interval.current ) {
					setFrequency( 10000 );
				}

				setActiveIndex( index );
			};

			return (
				<span
					className={ `selector ${ activeIndex === index ? 'active' : '' }` }
					key={ key }
					onClick={ handleClick }
				/>
			);
		}, [] )
		, [ activeIndex, testimonials ]
	);

	return (
		<Section id="A_Partners">
			<div className="testimonials">
				<div>
					<header>
						<h2>Just Ask Our Partners</h2>
					</header>
					{ mappedTestimonials[ activeIndex ]}
				</div>
				<div className="selectors">
					{ mapSelectors() }
				</div>
				<div className="logoBanner">
					<div className="logo-wrapper first">
						{ mappedLogos() }
					</div>
					<div className="logo-wrapper second">
						{ mappedLogos() }
					</div>
				</div>
			</div>
		</Section>
	);
};

export default Testimonials;
